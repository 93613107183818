import React, { useContext } from "react";
import clipboardCopy from 'clipboard-copy';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MiContexto from '../../MiContexto'

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const CHANNELS_OPTIONS = [
    { name: "Instagram", color: "#d62976", ico: "fa fa-instagram fa-brands", description: <InstgramDesc />, steps: <InstgramSteps /> },
    { name: "Facebook", color: "#1e7cf2", ico: "fa fa-facebook fa-brands", description: <FacebookDesc />, steps: <FacebookSteps /> },
    { name: "Youtube", color: "#c53b35", ico: "fa fa-youtube fa-brands", description: <YoutubeDesc />, steps: <YoutubeSteps /> },
    { name: "Website", color: "#FBAF5E", ico: "fa fa-globe", description: <WebsiteDesc />, steps: <WebsiteSteps /> },
    { name: "TikTok", color: "#fe2b54", ico: "tiktok-icon fa-brands", description: <TikTokDesc/> ,steps:<TikTokSteps/>},
    { name: "EmailMarketing", color: "#d696bb", ico: "fa fa-envelope", description: "No hay descripcion" },
    { name: "Otro", color: "#aae33d", ico: "fa fa-home", description: "No hay descripcion" }
]



export const getChannelIco = (channelName) => {
    const target = CHANNELS_OPTIONS.find(ch => ch.name === channelName)
    if (target) return target.ico
    else return "fa fa-question"
}
export const getChannelColor = (channelName) => {
    const target = CHANNELS_OPTIONS.find(ch => ch.name === channelName)
    if (target) return target.color
    else return "#000"
}

export const getChannelDescription = (channelName) => {
    const target = CHANNELS_OPTIONS.find(ch => ch.name === channelName)
    if (target) return target.description
    else return "No hay descripcion"
}

export const getChannelSteps = (channelName) => {
    const target = CHANNELS_OPTIONS.find(ch => ch.name === channelName)
    if (target) return target.steps
    else return "No hay descripcion"
}


function copiarTexto(aText) {
    // const { t } = useTranslation();
    clipboardCopy(aText)
        .then(() => {
            alert("Enlace copiado al portapapeles");
        })
        .catch((error) => {
            console.error('Error al copiar el texto: ', error);
        });
};


// INSTAGRAM****************************************
function InstgramDesc() {
    return (
        <div className="d-flex justify-content-center">
            <div className="col-12 col-md-9">
                <div className="mb-1 h6 text-center my-1 font-weight-bold">Sube contenido a Instagram</div>
                <div className="text-center my-2"><img className="rounded" src='images/insta2.jpg' style={{ width: "200px" }}></img></div>
                <div className="mt-2"><i className="fa fa-check mr-1 mt-2" />Sube Historias, Reels y Publicaciones junto a tu enlace de ventas o codigo de descuento. </div>
                <div className="my-0 py-0"><i className="fa fa-check mr-1 mt-2" />También puedes compartir el contenido de nuestra cuenta de Instagram oficial.</div>
                <div className="my-2"><i className="fa fa-check mr-1" />Puedes utilizar tu cuenta personal o crear una cuenta exclusiva para promocionar.</div>
                <div className="my-2"><i className="fa fa-check mr-1" />Obten ganancias por cada referido que compre con tu enlace de ventas o código de descuento.</div>
            </div>
        </div>
    )
}
function InstgramSteps() {

    const reseller = useContext(MiContexto).reseller
    const currentLanguage = localStorage.getItem('i18nextLng').split('-')[0]
    let instagramLink = "https://www.instagram.com/catwatchful_es/"
    if (currentLanguage === 'en') instagramLink = "https://www.instagram.com/catwatchful_en/"
    return (
        <div className="text-left p-2" >

            <div className="mb-1 ">
                <span className="bg-dark text-white rounded px-2 h4 mr-1" >1</span>
                Sigue nuestra cuenta de Instagram.
                <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => window.open(instagramLink, "_blank")}>Seguir</button>
            </div>

            <div className="mb-2 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">2</span>
                Comparte nuestras Historias y Publicaciones.
                <div>Sube contenido promocional el cual puedes extraer de nuestra cuenta oficial de Instagram o también crear tu propio contenido.</div>
                <div>También puedes extraer material promocional de nuestra sitio Web: <a className="text-dark font-weight-bold ml-1" href="https://catwatchful.es" target="_blank"><u>catwatchfules</u></a></div>
            </div>

            <div className="mb-0 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">3</span>
                Incluye tu enlace de ventas en cada Publicación o Historia que subas:
                <span className=" ml-1"><Link className="font-weight-bold text-dark" to={`https://${reseller.info.referLink}`} target="_blank"><u>{reseller.info.referLink}</u> </Link><i className="fa fa-clone ml-1 text-dark" onClick={() => copiarTexto(reseller.info.referLink)} /></span>
                <div>Si se trata de una cuenta exlusiva también puedes incluir tu enlace de ventas en el perfil de la cuenta.</div>
            </div>

            <div className="mb-0 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">4</span>
                Por cada persona realice una compra utilizando tu enlace de ventas o codigo de descuento <span className="text-dark font-weight-bold">({reseller.info.referCode})</span> recibiras una comisión de hasta el 30%
            </div>
        </div>
    )
}


// FACEBOOK****************************************
function FacebookDesc() {
    return (
        <div className="d-flex justify-content-center">
            <div className="col-12 col-md-9">
                <div className="mb-1 h6 text-center my-1 font-weight-bold">Promociona desde Facebook</div>
                <div className="text-center my-2"><img className="rounded" src='images/fb1.jpg' style={{ width: "200px" }}></img></div>
                <div className="my-2"><i className="fa fa-check mr-1" />Comparte el contenido de nuestra pagina oficial de Facebook o sube tu propio contenido promocional.</div>
                <div className="my-2"><i className="fa fa-check mr-1" />Puedes utilizar tu cuenta personal o crear una cuenta exclusiva para promocionar.</div>
                <div className="mt-2"><i className="fa fa-check mr-1" />Agrega tu enlace de ventas a cada Post o Historia que compartas y genera ganancias por cada venta que se haga desde tu enlace.</div>
            </div>
        </div>
    )
}

function FacebookSteps() {
    const reseller = useContext(MiContexto).reseller
    const currentLanguage = localStorage.getItem('i18nextLng').split('-')[0]
    let facebookLink = "https://www.facebook.com/cwatchful.esp"
    if (currentLanguage === 'en') facebookLink = "https://www.facebook.com/catsWatchful"
    return (
        <div className="text-left p-2" >

            <div className="mb-1 ">
                <span className="bg-dark text-white rounded px-2 h4 mr-1" >1</span>
                Sigue nuestra pagina de Facebook
                <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => window.open(facebookLink, "_blank")}>Seguir</button>
            </div>

            <div className="mb-2 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">2</span>
                Comparte nuestros Posteos e Historias en tu muro.
            </div>
            
            <div className="mb-0 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">3</span>
                Incluye tu enlace de ventas o codigo de descuento en cada Posteo o Historia que compartas:
                <span className=" ml-1"><Link className="font-weight-bold text-dark" to={`https://${reseller.info.referLink}`} target="_blank">{reseller.info.referLink} </Link><i className="fa fa-clone ml-1 text-dark" onClick={() => copiarTexto(reseller.info.referLink)} /></span>
            </div>

            <div className="mb-0 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">4</span>
                Por cada persona que realice una compra desde tu enlace de ventas o utilice tu codigo de descuento <span className="text-dark font-weight-bold">({reseller.info.referCode})</span> recibiras una comisión de hasta el 30%
            </div>
        </div>
    )
}


// YOUTUBE****************************************
function YoutubeDesc() {
    return (
        <div className="d-flex justify-content-center">
            <div className="col-12 col-md-9">
                <div className="mb-1 h6 text-center my-1 font-weight-bold">Crea videos en Youtube</div>
                <div className="text-center"><img className="rounded" src='images/youtube1.jpg' style={{ width: "200px" }}></img></div>
                <div className="my-2"><i className="fa fa-check mr-1" />Sube videos a la plataforma Youtube hablando sobre la aplicación Catwatchful.</div>
                <div className="my-2"><i className="fa fa-check mr-1" />Puedes hacer tutoriales, descripción de funciones y caracteristicas o lo que tu consideres necesario para promocionar de manera eficaz. </div>
                <div className="mt-2"><i className="fa fa-check mr-1" />Deja tu enlace de venta o codigo de descuento en la descripción de cada video y por cada viewer que concrete una compra recibiras tu ganancia.</div>
            </div>
        </div>
    )
}

function YoutubeSteps() {

    let youtubeVideos = [
        { channel: "Catwatchful", url: 'https://www.youtube.com/results?search_query=catwatchful' },
        { channel: "TecNìvoro", url: 'https://www.youtube.com/watch?v=aErVewrtl9M' },
        { channel: "Alexius Tv", url: 'https://www.youtube.com/watch?v=k7IrYX62_k0' },
        { channel: "Rincón Tecnológico", url: 'https://www.youtube.com/watch?v=uUD8pI6Zhao' },
        { channel: "RickWTF", url: 'https://www.youtube.com/watch?v=gyaQKUdrcTA' },
        { channel: "Zeicor", url: 'https://www.youtube.com/watch?v=3QsN7PmuBMA' }
    ]


    const reseller = useContext(MiContexto).reseller
    const currentLanguage = localStorage.getItem('i18nextLng').split('-')[0]
    let instagramLink = "https://www.instagram.com/catwatchful_es/"
    if (currentLanguage === 'en') instagramLink = "https://www.instagram.com/catwatchful_en/"
    return (
        <div className="text-left p-2" >

            <div className="mb-1 ">
                <span className="bg-dark text-white rounded px-2 h4 mr-1" >1</span>
                Crea un canal de Youtube o usa el que ya tienes.
            </div>

            <div className="mb-2 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">2</span>
                Sube videos hablando sobre la aplicación. Puedes hacer tutoriales, descripción de funciones y caracteristicas o lo que tu consideres necesario para promocionar de manera eficaz.
            </div>

            <div className="mb-0 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">3</span>
                Incluye tu enlace de ventas o codigo de descuento en la descripción de cada video que subas a la plataforma:
                <span className=" ml-1"><Link className="font-weight-bold text-dark" to={`https://${reseller.info.referLink}`} target="_blank">{reseller.info.referLink} </Link><i className="fa fa-clone ml-1 text-dark" onClick={() => copiarTexto(reseller.info.referLink)} /></span>
            </div>

            <div className="mb-0 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">4</span>
                Por cada persona realice una compra utilizando tu enlace recibiras una comisión de hasta el 30%
            </div>


            <div className="mt-1">

                <div className="mb-2">En Youtube encontraras una gran variedad de videos que puedes utilizar de ejemplo para promocionar Catwatchful.</div>

                {youtubeVideos.map((video, index) => {
                    return (
                        <div key={index}>
                            <a className="text-warning" href={video.url} target="_blank"><i className="fa fa-youtube fa-brands" /> {`${video.channel}`}</a>
                        </div>
                    )
                })
                }
            </div>
        </div>
    )
}


// WEBBLOG****************************************
function WebsiteDesc() {
    return (
        <div className="d-flex justify-content-center">
            <div className="col-12 col-md-9 text-dark">
                <div className="mb-1 h6 text-center my-1 font-weight-bold">Promociona en un Website o Blog</div>
                <div className="text-center"><img className="rounded" src='images/webblog1.jpg' style={{ width: "200px" }}></img></div>
                <div className="my-2"><i className="fa fa-check mr-1" />Crea una pagina web blog o hablando sobre la aplicación Catwatchful.</div>
                <div className="my-2"><i className="fa fa-check mr-1" />Puedes realizar tutoriales, descripción de funciones y caracteristicas o lo que tu consideres necesario para promocionar de manera eficaz. </div>
                <div className="mt-2"><i className="fa fa-check mr-1" />Invita a los usuarios a comprar la aplicacion utilizando tu enlace de venta o codigo de descuento y asi obtendras hasta un 30% de comisión por cada venta. </div>
            </div>
        </div>
    )
}

function WebsiteSteps() {
    const reseller = useContext(MiContexto).reseller
    return (
        <div className="text-left p-2 text-dark" >

            <div className="mb-1 ">
                <span className="bg-dark text-white rounded px-2 h4 mr-1" >1</span>
                Crea una pagina Web o Blog hablando sobre la aplicación Catwatchful.
            </div>

            <div className="mb-2 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">2</span>
                Puedes hacer tutoriales, descripción de funciones y caracteristicas o lo que tu consideres necesario para promocionar de manera eficaz.
            </div>

            <div className="mb-0 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">3</span>
                Incluye tu enlace de ventas o codigo de descuento en la pagina Web o Blog que crees y has que los usuarios compren la aplicacion desde tu enlace:
                <span className=" ml-1"><Link className="font-weight-bold text-dark" to={`https://${reseller.info.referLink}`} target="_blank">{reseller.info.referLink} </Link><i className="fa fa-clone ml-1 text-dark" onClick={() => copiarTexto(reseller.info.referLink)} /></span>
            </div>

            <div className="mb-0 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">4</span>
                Por cada persona realice una compra utilizando tu enlace o codigo de descuento recibiras una comisión de hasta el 30%
            </div>
        </div>
    )
}



// TIKTOK****************************************
function TikTokDesc() {
    return (
        <div className="d-flex justify-content-center">
            <div className="col-12 col-md-9 text-light text-dark">
                <div className="mb-1 h6 text-center my-1 font-weight-bold text-dark">Sube contenido en TikTok</div>
                <div className="text-center"><img className="rounded" src='./images/tiktok1.jpg' style={{ width: "200px" }}></img></div>
                <div className="my-2"><i className="fa fa-check mr-1" />Crea videos en TikTok hablando sobre la aplicación Catwatchful.</div>
                <div className="my-2"><i className="fa fa-check mr-1" />Puedes hacer tutoriales, descripción de funciones y caracteristicas o lo que tu consideres necesario para promocionar de manera eficaz. </div>
                <div className="mt-2"><i className="fa fa-check mr-1" />Invita a los usuarios a comprar la aplicacion utilizando tu enlace de venta o codigo de descuento y asi obtendras hasta un 30% de comisión por cada venta. </div>
            </div>
        </div>
    )
}
function TikTokSteps() {
    const reseller = useContext(MiContexto).reseller
    return (
        <div className="text-left p-2 text-dark" >

            <div className="mb-1 ">
                <span className="bg-dark text-white rounded px-2 h4 mr-1" >1</span>
                Crea una cuenta en TikTok.
            </div>

            <div className="mb-2 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">2</span>
                Sube videos hablando sobre la aplicación. Puedes hacer tutoriales, descripción de funciones y caracteristicas o lo que tu consideres necesario para promocionar de manera eficaz.
            </div>

            <div className="mb-0 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">3</span>
                Incluye tu enlace de ventas o codigo de descuento en la descripción de cada video que subas a la plataforma:
                <span className=" ml-1"><Link className="font-weight-bold text-dark" to={`https://${reseller.info.referLink}`} target="_blank">{reseller.info.referLink} </Link><i className="fa fa-clone ml-1 text-dark" onClick={() => copiarTexto(reseller.info.referLink)} /></span>
            </div>

            <div className="mb-0 my-4">
                <span className="bg-dark text-white rounded px-2 h4 mr-1">4</span>
                Por cada persona realice una compra utilizando tu enlace o codigo de descuento recibiras una comisión de hasta el 30%
            </div>
        </div>
    )
}
